import React from "react";
import services1 from "../images/services1.jpg"
import services2 from "../images/services2.jpg"
import services3 from "../images/services3.jpg"

export default function ServicePage() {
    return (
        <div className="service-page">
            <div className="service-page__image--left">
                <img src={services1} />
            </div>
            <div className="service-page__info">
                <div className="service-page__header">
                    <h2>
                        Services
                    </h2>
                </div>
                <div className="service-page__description">
                    <p>
                        Our expertise spans a diverse spectrum of spaces,
                        including modern corporate offices, inviting residential
                        havens, dynamic retail environments, and more. With
                        a keen eye for detail and a commitment to excellence,
                        our services encompass concept development, space
                        planning, interior decoration, and project
                        management.
                    </p>
                    <br />
                    <p>
                        We source only the finest materials, ensuring longevity
                        and sustainability in every project. Our extensive
                        portfolio showcases a wide range of successfully
                        completed projects, a testament to our versatility and
                        ability to exceed expectations. Trust Avigna Enterprises
                        to elevate your interior dreams into reality, no matter
                        the scale or scope.
                    </p>
                </div>
            </div>
            <div className="service-page__image--right">
                <img src={services2} />
                <img src={services3} />
            </div>
        </div>
    )
}