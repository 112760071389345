import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Navbar from "./components/navbar";
import Home from "./components/home";
import About from "./components/about";
import Contact from "./components/contact";

import './styles/styles.scss'
import Portfolio from "./components/Portfolio";
import PortfolioIntro from "./components/PortfolioIntro";
import Vision from "./components/Vision";
import Info from "./components/InfoPage";
import Footer from "./components/Footer";
import "@fontsource/playfair-display";
import "@fontsource/poppins";
import Hamburger from "./components/Hamburger";
import "@fontsource/poppins";
import MobileHeader from "./components/MobileHome";
import MobileHome from "./components/MobileHome";
import { useState } from "react";
import MobileInfo from "./components/MobileInfo";
import MobilePortfolio from "./components/MobilePortfolio";
import MobileContact from "./components/MobileContact";


function App() {
  const [mobile, setMobile] = useState(window.innerWidth <= 820 ? true : false)

  return (
    <Router>
      <div className="app">

        {mobile ? <Hamburger /> : <Navbar />}
        <Routes>
          <Route path='/' exact element={mobile ? <MobileHome /> : <Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={mobile ? <MobileContact /> : <Contact />} />
          <Route path='/portfolio-intro' element={<PortfolioIntro />} />
          <Route path='/portfolio' element={mobile ? <MobilePortfolio /> : <Portfolio />} />
          <Route path='/info' element={mobile ? <MobileInfo /> : <Info />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
