import react from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from "../images/logo.png"

export default function Navbar() {
  const location = useLocation()
  return (
    <div className='nav'>
      <div className='nav-logo'>
        <img src={logo} />
      </div>
      <nav className="nav-bar">
        <Link to='/'>
          <li className={location.pathname === '/' ? 'nav-bar-active' : ''}>
            Home
          </li>
        </Link>

        <Link to='/about'>
          <li className={location.pathname === '/about' ? 'nav-bar-active' : ''}>
            About
          </li>
        </Link>
        
        <Link to='/info'>
          <li className={location.pathname === '/info' ? 'nav-bar-active' : ''}>
            Info
          </li>
        </Link>

        <Link to='/portfolio-intro'>
          <li className={location.pathname === '/portfolio-intro' ? 'nav-bar-active' : ''}>
            Portfolio
          </li>
        </Link>
        <Link to='/contact'>
          <li className={location.pathname === '/contact' ? 'nav-bar-active' : ''}>
            Contact
          </li>
        </Link>
      </nav>

    </div>

  )
}