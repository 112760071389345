import React from "react";

export default function HeaderIntro({
    image,
    description,
    title
}) {
    return (
        <div className="header-intro">
            <div className="header-intro__info">
                <div className="header-intro-title">
                    <h1>{title}</h1>
                </div>
                <div className="header-intro__description">
                    <p>{description}</p>
                </div>
            </div>
            <div className="header-intro__image">
                <img src = {image} />
            </div>
        </div>
    )
}