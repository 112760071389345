import React from "react";
import ServicePage from "./ServicePage";
import vision1 from "../images/vision1.jpg"
import vision2 from "../images/vision2.jpg"

const data = [{
    "header": "Inspiring Creativity:",
    "point": "We aim to inspire and nurture creativity in every interior space."
},
{
    "header": "Functional Elegance: ",
    "point": "We blend elegance with practicality, crafting spaces that are both beautiful and functional."
},
{
    "header": "Client-Centric: ",
    "point": "Putting clients at the core of each project, we create personalized interior solutions."
},
{
    "header": "Innovation: ",
    "point": "We lead with innovation, staying at the forefront of design trends and technology."
},
{
    "header": "Client Satisfaction: ",
    "point": "Ultimately,our success is measured by client satisfaction and the joy we bring to their spaces."
},

]
export default function Vision() {
    return (
        <div className="vision">
            <div className="vision__left">
                <div className="vision__content">
                    <div className="vision__left__title">
                        <h2>
                            Our Vision
                        </h2>
                    </div>
                    <div className="vision__left__subtitle">
                        At Avigna Enterprises, our vision is simple yet
                        profound:
                    </div>
                    <div className="vision__left__points">
                        <ul>
                            {data.map((item, index) => (
                                <li>
                                    <h4>
                                        {item.header}

                                        <span>
                                            {" " + item.point}
                                        </span>
                                    </h4>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="vision__right">
                <div className="vision__image">
                    <img src={vision1} />
                </div>
                <div className="vision__text">
                    <h2>JOIN US ON OUR JOURNEY TO TRANSFORM
                        SPACES AND TURN DREAMS INTO REALITY</h2>
                </div>
                <div className="vision__image">
                    <img src={vision2} />
                </div>
            </div>
        </div>
    )
}