import React from "react";
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import HeroBanner from "./HeroBanner";

SwiperCore.use([Autoplay, Navigation, EffectFade, Pagination]);

export default function Swiper({ services }) {
    console.log(services)
    return (
        <SwiperReact
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
                delay: 4500,
                disableOnInteraction: true,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            effect="fade"
            fadeEffect={{
                crossFade: true,
            }}
        >
            {services?.map((item, index) => (
                <SwiperSlide>
                    {item}
                </SwiperSlide>
            ))}
        </SwiperReact>
    );
}
