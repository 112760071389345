import React from "react";


export default function Services({
    images,
    title,
    subtitle,
    description
}) {
    return (
        <div className="service">
            {images.map((item, index) => (
                <div className="service-img">
                    <img key={index} src={item} />
                </div>
            ))}
            <div className="service__content">
                <div className="service__content-info">
                    <div className="service__content-title">
                        <h2>{title}</h2>
                    </div>
                    <div className="service__content-subtitle">
                        <h3>{subtitle}</h3>
                    </div>
                    <div className="service__content-desc">
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </div>





    )
}