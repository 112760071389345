import React from "react";
import Vision from "./Vision";
import ServicePage from "./ServicePage";

export default function Info(){
    return(
        <div className="infox">
            <Vision/>
            <ServicePage/>
        </div>
    )
}