import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';



export default function Footer() {
    return (
        <div className="footer">

            <div className="footer_links">
                <div className="footer__links">
                    <h5>Links</h5>
                    <ul>
                        <li>
                            <Link to='/' className="LinkStyle">Home</Link>
                        </li>
                        {window.innerWidth >= 576 && <li>
                            <Link to='/about' className="LinkStyle">About</Link>
                        </li>}
                        <li>
                            <Link to='/info' className="LinkStyle">Info</Link>
                        </li>
                        <li>
                            <Link to='/portfolio' className="LinkStyle">Portfolio</Link>
                        </li>
                    </ul>
                </div>

                <div className="footer_content">
                    <h5>Founders</h5>
                    <ul>

                        <li>Subramanya BG</li>
                        <li>Suji Subramanya</li>

                    </ul>

                </div>
                <div className="add">
                    <h5>Address</h5>
                    <p>#583, 16th main Road . Padmanabhanagar, Bangalore – 560070. </p>

                </div>
                <div className="Socials">
                    <h5>Socials</h5>
                    <ul className="socials">
                        {/* <li><Link to='https://www.instagram.com/_.chir_g_/' className="LinkStyle"><FontAwesomeIcon icon={faFacebook} /></Link></li>
                        <li><Link to='https://www.instagram.com/_.chir_g_/' className="LinkStyle"><FontAwesomeIcon icon={faInstagram} /></Link></li> */}
                        <li>
                            <a href="mailto:subramanya.belur@avigna.org" className="LinkStyle">
                            <FontAwesomeIcon icon={faEnvelope} /> {/* Assuming you have an email icon */}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="contactt">
                    <Link to='/contact' className="LinkStyle" style={{ color: '#895f43' }}>Contact us</Link>

                </div>
            </div>


            <div className="copy">
                <p>Copyright <FontAwesomeIcon icon={faCopyright} /> Avigna Enterprises. All rights reserved.</p>

            </div>
        </div>
    )
}



