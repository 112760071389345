import React from "react";

import HeaderIntro from "./headerIntro";
import home from "../images/home.jpg";
export default function Home() {
    return (
        <div className="home">
            <HeaderIntro 
                image={home}
                title ="HOME"
                description="Discover Avigna Enterprises, where we exceed expectations in crafting exceptional
                             interiors. From contemporary offices, cozy homes, to vibrant retail spaces, we prioritize
                             excellence and meticulous attention to detail in every project.
                             Using premium materials and showcasing a diverse portfolio of successful projects, we're
                             dedicated to inspiring and delighting. Welcome to Avigna Enterprises."
            />
        </div>
    )
}