import React, { useEffect } from "react";

import con from "../images/mobile contact1.jpg"
import map from "../images/map.jpeg"

export default function MobileContact() {

    useEffect(()=>{
        const top = document.getElementById("ham")
        top.scrollIntoView({behavior:"smooth"})
    },[])

    function handleClick(){
        window.open("https://maps.google.com?q=Avigna%20Enterprises,%20583,%2016th%20Main%20Rd,%20Padmanabhanagar,%20Bengaluru,%20Karnataka%20560070&ftid=0x3bae3f7ef140b61d:0x48ad94ee344d7faf&hl=en-US&gl=in&entry=gps&lucs=,47071704")
    }
    return (
        <div className="mobile-contact" id="top">
            <div className="mobile-contact__image">
                <img src={con}></img>
            </div>
            <div className="mobile-contact__info__container">
                <div className="mobile-contact__info__header">
                    <h2>
                        Contact Us
                    </h2>
                </div>
                <div className="mobile-contact__info">
                    <div className="mobile-contact__info__content">
                        <p>
                            We are eager to hear from you and discuss how we can bring your interior visions to life. Whether you have questions, ideas, or would like to start a project, don't hesitate to reach out.
                        </p>

                        <div className="contact__connect">
                            <h2>
                                Phone:
                                <span>
                                    {" "} 9845152248 / 9731031631
                                </span>
                            </h2>
                            <h2>
                                Email:
                                {" "}<a href="mailto:subramanya.belur@avigna.org">subramanya.belur@avigna.org</a>
                            </h2>
                        </div>
                    </div>
                    <div className="mobile-contact__map">
                        <img src={map} onClick={handleClick}/>
                    </div>
                </div>

            </div>
        </div>
    )
}