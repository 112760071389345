import React from "react";
import HeaderIntro from "./headerIntro";
import about from "../images/about_us.jpg"
import about1 from "../images/about_us1.jpg"
export default function About() {
    return (
        <div className="about">
            <div className="about__left">
                <div className="about__left-img">
                    <img src={about} alt="About Us" />
                </div>
            </div>
            <div className="about__headerIntro">
                <HeaderIntro
                    title="ABOUT US"
                    image={about1}
                />
                <div className="about__info">
                    <p>
                        At Avigna Enterprise, we excel in turning your interior dreams into reality. With a proven track record,
                        we are your go-to experts for crafting exceptional interior projects across a multitude of spaces. From
                        modern corporate offices to cozy residences, we blend creativity and functionality seamlessly.
                        <br/>
                        <br/>
                        Our dedicated team of designers and skilled craftsmen breathe life into your visions, delivering spaces
                        that are not only aesthetically pleasing but also highly functional- at highly compet prices , made of
                        the best quality materials in the market. Whether it’s an innovative workspace, a welcoming home, or a
                        dynamic retail setting, we deliver it all!
                    </p>
                </div>
            </div>
        </div>
    )
}