import React from "react"
import Services from "./Services"
import Swiper from "./Swiper"
import cc1 from "../images/cc1.jpg";
import cc2 from "../images/cc2.jpg";
import cc3 from "../images/cc3.jpg";
import cc4 from "../images/cc4.jpg";
import office1 from "../images/office_1.jpg";
import office2 from "../images/office_2.jpg";
import office3 from "../images/office_3.jpg";
import office from "../images/office.jpg";
import cafeteria1 from "../images/cafeteria_1.jpg";
import cafeteria2 from "../images/cafeteria_2.jpg";
import cafeteria3 from "../images/cafeteria_3.jpg";
import cafeteria4 from "../images/cafeteria_4.jpg";
import mantri1 from "../images/mantri_1.jpg";
import mantri2 from "../images/mantri_2.jpg";
import mantri3 from "../images/mantri_3.jpg";
import mantri4 from "../images/mantri_4.jpg";
import incubex1 from "../images/incubex1.jpg";
import incubex2 from "../images/incubex2.jpg";
import incubex3 from "../images/incubex3.jpg";
import incubex4 from "../images/incubex4.jpg";
import leongrill1 from "../images/leongrill_1.jpg";
import leongrill2 from "../images/leongrill_2.jpg";
import leongrill3 from "../images/leongrill_3.jpg";
import leongrill4 from "../images/leongrill_4.jpg";




const data = [{
    "images":
        [
            cc1,cc2,cc4,cc3

        ],
    "title": "Client space and Cafeteria",
    "subtitle": "Bangalore",
    "desctiption": "An inviting office cafeteria, a testament to our blend of aesthetics and functionality. It' s a space where office employees find respite and inspiration amid their daily endeavors."
},
{
    "images":
    [office,office3,office2,office1],

       
    "title": "Break out Office Spaces",
    "subtitle": "Hyderabad",
    "desctiption": "An office breakout space into a dynamic hub for collaboration and relaxation. With innovative design elements and comfortable furnishings, this space now encourages creativity and rejuvenation, fostering a more productive work environment."
},
{
    "images":
        [cafeteria3,cafeteria2,cafeteria1,cafeteria4],
    "title": "Terrace Cafeteria",
    "subtitle": "Bangalore",
    "desctiption": "This inviting space combines nature-inspired design with modern comfort, providing a tranquil escape for office occupants to relax and rejuvenate."
},
{
    "images":
        [mantri4,mantri3,mantri2,mantri1],
    "title": "Residential -Mantri Serenity",
    "subtitle": "Bangalore",
    "desctiption": "A timeless blend of elegance and functionality, transforming a house into a welcoming sanctuary. Our meticulous attention to detail and creative expertise are showcased in every corner, ensuring a space that not only captivates but also enhances everyday living."
},
{
    "images":
        [incubex1,incubex2,incubex3,incubex4],
    "title": "Office Space Incubex",
    "subtitle": "Bangalore",
    "desctiption": "Revolutionized workspaces with innovative design concepts and a focus on productivity and aesthetics."
},
{
    "images":
        [leongrill4,leongrill1,leongrill2,leongrill3],
    "title": "Commercial Establishment-Leon Grill",
    "subtitle": "Bangalore",
    "desctiption": "A space that captures attention and drives business.Witness how we've seamlessly integrated branding,functionality,and aesthetics to craft an environment that leaves a lasting impression on customers."
},
]
export default function Portfolio() {
    return (
        <div className="portfolio">
            <Swiper services={data?.map((item, index) => (
                <Services
                    key={index}
                    images={item?.images}
                    title={item?.title}
                    subtitle={item?.subtitle}
                    description={item?.desctiption}
                />
    ))} />
        </div>
    )
}