import React from "react";
import HeaderIntro from "./headerIntro";
import { Link } from "react-router-dom"; // Import the Link component
import home from "../images/home.jpg"
export default function PortfolioIntro() {
  return (
    <div className="portfolio-intro">
      <HeaderIntro
        image={home}
        title="Portfolio"
        description="Explore our diverse portfolio of projects, spanning corporate offices, residential havens, and dynamic retail spaces. Witness the fusion of creativity and functionality in our meticulously crafted interiors. Let our work speak for itself as we turn visions into remarkable realities."
      />
      <div className="portfolio-intro__cta">
        <Link to="/portfolio">Explore</Link>
      </div>
    </div>
  );
}
