import React from "react";
import contact from "../images/Contact_us.jpg"
export default function Contact() {
    return (
        <div className="contact">
            <div className="contact__image">
                <img src={contact}/>
            </div>
            <div className="contact__info">
                <div className="contact__header">
                    <h2>
                        Contact Us
                    </h2>
                </div>
                <div className="contact__description">
                    <p>
                        We are eager to hear from you and
                        discuss how we can bring your
                        interior visions to life. Whether you
                        have questions, ideas, or would like
                        to start a project, don't hesitate to
                        reach out.
                    </p>
                </div>
                <div className="contact__connect">
                    <h2>
                        Phone:
                        <span>
                           {" "} 9845152248 / 9731031631
                        </span>
                    </h2>
                    <h2>
                        Email:
                        {" "}<a href="mailto:subramanya.belur@avigna.org">subramanya.belur@avigna.org</a>
                    </h2>
                </div>
            </div>
        </div>
    )
}