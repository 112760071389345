import { Link } from "react-router-dom";
import vision1 from "../images/vision1.jpg"
import img1 from "../images/mobile home1.jpeg"
import { useEffect } from "react";
export default function MobileHome() {
    useEffect(()=>{
        const top = document.getElementById("ham")
        top.scrollIntoView({behavior:"smooth"})
    },[])
    return (
        <div className="m-home" id="top">
            <div className="m-home__img">
                <img src={img1} />
            </div>
            <div className="m-home__info">
                <div className="m-home__info__container">
                    <p>
                        Discover Avigna Enterprises, where we exceed expectations in crafting exceptional interiors. From contemporary offices, cozy homes, to vibrant retail spaces, we prioritize excellence and meticulous attention to detail in every project.
                    </p>
                    <p>
                    Using premium materials and showcasing a diverse portfolio of successful projects, we're dedicated to inspiring and delighting. Welcome to Avigna Enterprises.
                    </p>
                    <div className="m-home__cta">
                        <Link to="/portfolio">
                            See all Projects
                        </Link>
                    </div>
                </div>
            </div>
            <div className="m-home__img">
                <img src={vision1} />
            </div>
        </div>
    )
}