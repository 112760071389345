import React from "react";
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';

import 'swiper/swiper-bundle.min.css';


SwiperCore.use([Autoplay, Navigation, EffectFade, Pagination]);

export default function SwiperV2({ images }) {
    console.log(images)
    return (
        <SwiperReact
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
                delay: 4500,
                disableOnInteraction: true,
            }}
            pagination={{
                clickable: true,
            }}
            effect="fade"
            fadeEffect={{
                crossFade: true,
            }}
        >
            {images?.map((item, index) => (
                <SwiperSlide key={`image ${index}`}>
                    <img src={item}/>
                </SwiperSlide>
            ))}
        </SwiperReact>
    );
}
