
import MobileInfoIntro from "./MobileInfoIntro"
import about from "../images/about_us.jpg"
import info2 from "../images/mobile info2.jpg"
import info3 from "../images/mobile info 3.jpg"
import info1 from "../images/info1.jpeg"
import { useEffect } from "react"



export default function MobileInfo() {
    useEffect(()=>{
        const top = document.getElementById("ham")
        top.scrollIntoView({behavior:"smooth"})
    },[])
    return (
        <div className="m-info">
            <div className="m-info__image" id="top">
                <img src={info1} />
            </div>
            <div className="m-info__content">
                <MobileInfoIntro 
                    info1="At Avigna Enterprise, we excel in turning your interior dreams into reality. With a proven track record, we are your go-to experts for crafting exceptional interior projects across a multitude of spaces. From modern corporate offices to cozy residences, we blend creativity and functionality seamlessly."
                    info2="Our dedicated team of designers and skilled craftsmen breathe life into your visions, delivering spaces that are not only aesthetically pleasing but also highly functional- at highly compet prices , made of the best quality materials in the market. Whether it’s an innovative workspace, a welcoming home, or a dynamic retail setting, we deliver it all!"
                    image={about}
                    title="About Us"
                    variant="left"
                />
                <MobileInfoIntro 
                    info1=""
                    info2=""
                    image={info2}
                    title="Our Vision"
                    variant="right"
                />
                <MobileInfoIntro 
                    info1="Our expertise spans a diverse spectrum of spaces, including modern corporate offices, inviting residential havens, dynamic retail environments, and more. With a keen eye for detail and a commitment to excellence, our services encompass concept development, space planning, interior decoration, and project management. "
                    info2="We source only the finest materials, ensuring longevity and sustainability in every project. Our extensive portfolio showcases a wide range of successfully completed projects, a testament to our versatility and ability to exceed expectations. Trust Avigna Enterprises to elevate your interior dreams into reality, no matter the scale or scope."
                    image={info3}
                    title="Services"
                    variant="left"
                />
            </div>
        </div>
    )
}