import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo1 from "../images/logo1.png";
import logo from "../images/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Hamburger() { 
  const location = useLocation();
  const [click, setClick] = useState(false);

  const closeMenu = () => {
    setClick(false);
  };

  const handleClick = () => {
    setClick(!click);
  };

  return (
    <div className='navbar' id='ham'>
      <div className='nav-container'>
        <Link to="/" className="nav-logo">
          <img src={logo1} />
        </Link>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className='nav-items'>
            <Link to="/" activeClassName="active" className="nav-links" onClick={closeMenu}>
              Home
            </Link>
          </li>
          
          <li className='nav-items'>
            <Link to="/info" activeClassName="active" className="nav-links" onClick={closeMenu}>
              Info
            </Link>
          </li>
          <li className='nav-items'>
            <Link to="/portfolio" activeClassName="active" className="nav-links" onClick={closeMenu}>
              Portfolio
            </Link>
          </li>
          <li className='nav-items'>
            <Link to="/contact" activeClassName="active" className="nav-links" onClick={closeMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className="nav-icon" onClick={handleClick}>
        <FontAwesomeIcon icon={click ? faTimes : faBars} />
      </div>
    </div>
  )
}
