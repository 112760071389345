import React from "react";
import about from "../images/about_us.jpg"

const data = [{
    "header": "Inspiring Creativity:",
    "point": "We aim to inspire and nurture creativity in every interior space."
},
{
    "header": "Functional Elegance: ",
    "point": "We blend elegance with practicality, crafting spaces that are both beautiful and functional."
},
{
    "header": "Client-Centric: ",
    "point": "Putting clients at the core of each project, we create personalized interior solutions."
},
{
    "header": "Innovation: ",
    "point": "We lead with innovation, staying at the forefront of design trends and technology."
},

]


export default function MobileInfoIntro({
    title,
    image,
    info1,
    info2,
    variant
}) {
    return (
        <div className={`m-info-intro m-info-intro--${variant}`}>
            <div className="m-info-intro__content">
                <div className="m-info-intro__header">
                    <h2>
                        {title}
                    </h2>
                </div>
                {variant === "left" ? (<div className="m-info-intro__text">
                    {info1 &&
                        <p>
                            {info1}
                        </p>
                    }
                    {info2 &&
                        <p>
                            {info2}
                        </p>
                    }
                </div>) : (
                    <div className="m-info-intro__text--points">
                        <p>
                            {info1}
                        </p>
                        <div className="vision__left__points">
                            <ul>
                                {data.map((item, index) => (
                                    <li>
                                        <h4>
                                            {item.header}

                                            <span>
                                                {" " + item.point}
                                            </span>
                                        </h4>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
            <div className="m-info-intro__image">
                <img src={image} />
            </div>
        </div>
    )
}